<template>
  <div class="navigation-container">
    <div class="logo-container">
      <a href="https://www.aclum.org/">
        <img
          class="navigation-logo"
          src="@/assets/logo-ma.svg"
        >
      </a>
    </div>
    <div class="lang-container">
      <h3 class="sub-header">
        {{ lang[activeLang].page_title }}
      </h3>
      <!-- <form-select
        id="lang-select"
        :input="{ title: lang[activeLang].language_select.title, options: ['English', 'Spanish'], optionsObjects: lang[activeLang].language_select.options }"
        :active-lang="activeLang"
        class="caret-hide dark-mode"
        @formUpdate="$emit('formUpdate', $event)"
      /> -->
    </div>
  </div>
</template>

<script>
//import FormSelect from './FormSelect.vue'

export default {
  name: 'Navigation',
  components: {
    //FormSelect,
  },
  props: {
    activeLang: {
      type: String,
      default: "English",
    },
  },
  data() {
    return {}
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.navigation {
  display: flex;
  justify-content: space-between;
  margin: 0 16px 100px 16px;
  max-width: 1200px;
}

.navigation-logo {
  width: 200px;
  height: 110px;
}

#lang-select {
  width: 200px;
  margin-left: 20px;
  margin-top: 0;
}

.lang-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}

.lang-container .sub-header {
  display: none;
}

h3 {
  white-space: nowrap;
}

@media screen and (max-width: 500px) {
  .navigation {
    flex-direction: column;
    margin: 0;
    gap: 10px;
    padding-bottom: 0;
  }
  .lang-container {
    align-items: center;
  }
}

@media screen and (min-width: 700px) {
  .navigation {
    width: 80%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1000px) {
  .lang-container .sub-header {
    display: block;
  }
}

@media screen and (min-width: 1200px){
  .lang-container {
    flex-direction: row;
    align-items: center;
  }
}

@media screen and (min-width: 1400px) {
  .navigation {
    width: 70%;
    margin: 0 auto;
  }
}

</style>
